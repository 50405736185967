<template>
    <v-container v-click-outside="{ handler: onClickOutside, include }" fluid class="mx-0 px-0">
        <v-navigation-drawer
            v-model="drawer"
            fixed
            class="drawer pt-12"
            :width="drawer? '170px' : '60px'"
        >
            <v-list
                nav
                dense
            >
                <div v-for="(menuItem, index) in menu" :key="index" @click="setDrawer">
                    <v-list-item :to="menuItem.to" link>
                        <v-list-item-icon v-if="menuItem.icon">
                            <v-icon>{{ menuItem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-if="menuItem.title">{{ menuItem.title }}</v-list-item-title>
                    </v-list-item>
                </div>
            </v-list>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
    export default {
        name: 'Sidebar',
        data() {
            return {
                menu: [
                    {
                        title: 'Arenas',
                        to: '/arenas',
                        icon: 'mdi-stadium-outline'
                    },
                    {
                        title: 'Matches',
                        to: '/matches',
                        icon: 'mdi-gamepad-variant-outline'
                    },
                    {
                        title: 'Leagues',
                        to: '/leagues',
                        icon: 'mdi-podium-gold'
                    },
                    {
                        title: 'Seasons',
                        to: '/seasons',
                        icon: 'mdi-calendar'
                    },
                    {
                        title: 'Teams',
                        to: '/teams',
                        icon: 'mdi-account-group-outline'
                    },
                    {
                        title: 'Players',
                        to: '/players',
                        icon: 'mdi-account-outline'
                    },
                    {
                        title: 'Articles',
                        to: '/articles',
                        icon: 'mdi-newspaper-variant'
                    },
                    {
                        title: 'Philosophy',
                        to: '/philosophy/edit',
                        icon: 'mdi-book-variant'
                    },
                    {
                        title: 'Regulation',
                        to: '/regulation/edit',
                        icon: 'mdi-abacus'
                    },
                    {
                        title: 'Faqs',
                        to: '/faqs',
                        icon: 'mdi-frequently-asked-questions'
                    },
                    {
                        title: 'Rules',
                        to: '/rules',
                        icon: 'mdi-police-badge-outline'
                    },
                    {
                        title: 'Team Applications',
                        to: '/team-applications',
                        icon: 'mdi-application-import'
                    }
                ]
            }
        },
        computed: {
            drawer: {
                get() {
                    return this.$store.getters.getDrawer
                },
                set(value) {
                    return value
                }
            }
        },
        methods: {
            onClickOutside() {
                this.setDrawer()
            },
            setDrawer() {
                if (this.drawer) {
                    this.$store.dispatch('setDrawer', !this.drawer)
                }
            },
            include() {
                return [document.querySelector('.included')]
            }
        }
    }
</script>

<style scoped>

a {
    color: #0060B6;
    text-decoration: none;
}

a:hover {
    color:#00A0C6;
    text-decoration:none;
    cursor:pointer;
}
.sub-text{
    font-size: 0.8rem;
    color: black;
}
.container {
    align-self: center;
    height: 100%;
}
</style>
