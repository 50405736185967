export default {
    state: {
        token: localStorage.getItem('big-cms-api-token') === 'null' ? null : localStorage.getItem('big-cms-api-token'),
        drawer: localStorage.getItem('drawer') === 'null' ? false : localStorage.getItem('drawer') === 'true',
        refreshToken: localStorage.getItem('big-cms-refreshToken'),
        refreshTokenExpiration: localStorage.getItem('big-cms-refreshTokenExpiration'),
        playerFilters: null,
        matchFilters: null,
        teamFilters: null,
        page: 1
    },
    mutations: {
        setToken(state, value) {
            state.token = value
        },
        setDrawer(state, value) {
            state.drawer = value
        },
        setRefreshToken(state, value) {
            state.refreshToken = value
        },
        setRefreshTokenExpiration(state, value) {
            state.refreshTokenExpiration = value
        },
        setPlayerFilters(state, value) {
            state.playerFilters = value
        },
        setMatchFilters(state, value) {
            state.matchFilters = value
        },
        setTeamFilters(state, value) {
            state.teamFilters = value
        },
        setPage(state, value) {
            state.page = value
        }
    },
    actions: {
        logout({ dispatch }) {
            dispatch('setToken', null)
            dispatch('setDrawer', null)
            dispatch('setRefreshToken', null)
            dispatch('setRefreshTokenExpiration', null)
            dispatch('setPlayerFilters', null)
            dispatch('setMatchFilters', null)
            dispatch('setTeamFilters', null)
        },
        login({ dispatch }, [resp]) {
            if (resp.data.token) {
                dispatch('setToken', resp.data.token)
                dispatch('setDrawer', false)
                dispatch('setRefreshToken', resp.data.refreshToken)
                dispatch('setRefreshTokenExpiration', resp.data.refreshTokenExpiration)
            }
        },
        setPage({ commit }, page) {
            commit('setPage', page)
        },
        setTeamFilters({ commit }, teamFilters) {
            commit('setTeamFilters', teamFilters)
        },
        setMatchFilters({ commit }, matchFilters) {
            commit('setMatchFilters', matchFilters)
        },
        setPlayerFilters({ commit }, playerFilters) {
            commit('setPlayerFilters', playerFilters)
        },
        setToken({ commit }, token) {
            localStorage.setItem('big-cms-api-token', token)
            commit('setToken', token)
        },
        setDrawer({ commit }, drawer) {
            localStorage.setItem('drawer', drawer)
            commit('setDrawer', drawer)
        },
        setRefreshToken({ commit }, refreshToken) {
            if (refreshToken) {
                localStorage.setItem('big-cms-refreshToken', refreshToken)
                commit('setRefreshToken', refreshToken)
            } else {
                localStorage.removeItem('refreshToken')
                commit('setRefreshToken', null)
            }
        },
        setRefreshTokenExpiration({ commit }, refreshTokenExpiration) {
            if (refreshTokenExpiration) {
                localStorage.setItem('big-cms-refreshTokenExpiration', refreshTokenExpiration)
                commit('setRefreshTokenExpiration', refreshTokenExpiration)
            } else {
                localStorage.removeItem('big-cms-refreshTokenExpiration')
                commit('setRefreshTokenExpiration', null)
            }
        }
    },
    getters: {
        getToken: state => state.token,
        isLoggedIn(state) {
            // is string null because of local storage
            return state.token !== null
        },
        getDrawer: state => state.drawer,
        getRefreshToken: state => state.refreshToken,
        getRefreshTokenExpiration: state => state.refreshTokenExpiration,
        getPlayerFilters: state => state.playerFilters,
        getMatchFilters: state => state.matchFilters,
        getTeamFilters: state => state.teamFilters,
        getPage: state => state.page
    }
}
