import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import jwtUtil from '@/utils/jwtUtil'
import vueConfig from '@/../vue.config'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/routes/Home')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/routes/Login'),
        props: true
    },
    {
        path: '/arenas',
        name: 'Arenas',
        component: () => import('@/routes/Arena')
    },
    {
        path: '/arenas/:mode',
        name: 'Arena Create Form',
        component: () => import('@/forms/ArenaForm')
    },
    {
        path: '/arenas/:arenaId/:mode',
        name: 'Arena Edit Form',
        component: () => import('@/forms/ArenaForm')
    },
    {
        path: '/articles',
        name: 'Articles',
        component: () => import('@/routes/Article')
    },
    {
        path: '/articles/:mode',
        name: 'Article Create Form',
        component: () => import('@/forms/ArticleForm')
    },
    {
        path: '/articles/:articleId/:mode',
        name: 'Article Edit Form',
        component: () => import('@/forms/ArticleForm')
    },
    {
        path: '/players',
        name: 'Players',
        component: () => import('@/routes/Player')
    },
    {
        path: '/players/:mode',
        name: 'Player Create Form',
        component: () => import('@/forms/PlayerForm')
    },
    {
        path: '/players/:playerId/:mode',
        name: 'Player Edit Form',
        component: () => import('@/forms/PlayerForm')
    },
    {
        path: '/philosophy/:mode',
        name: 'Philosophy Form',
        component: () => import('@/forms/PhilosophyForm')
    },
    {
        path: '/regulation/:mode',
        name: 'Regulation Form',
        component: () => import('@/forms/RegulationForm')
    },
    {
        path: '/teams',
        name: 'Teams',
        component: () => import('@/routes/Team')
    },
    {
        path: '/teams/:mode',
        name: 'Team Create Form',
        component: () => import('@/forms/TeamForm')
    },
    {
        path: '/teams/:teamId/:mode',
        name: 'Team Edit Form',
        component: () => import('@/forms/TeamForm')
    },
    {
        path: '/matches',
        name: 'Matches',
        component: () => import('@/routes/Match')
    },
    {
        path: '/matches/:matchId/:mode',
        name: 'Match Edit Form',
        component: () => import('@/forms/MatchForm')
    },
    {
        path: '/stats/match/:matchId',
        name: 'Match Stat Page',
        component: () => import('@/routes/Stats')
    },
    {
        path: '/matches/:mode',
        name: 'Match Create Form',
        component: () => import('@/forms/MatchForm')
    },
    {
        path: '/leagues',
        name: 'Leagues',
        component: () => import('@/routes/League')
    },
    {
        path: '/leagues/:leagueId/:mode',
        name: 'League Edit Form',
        component: () => import('@/forms/LeagueForm')
    },
    {
        path: '/leagues/:mode',
        name: 'League Create Form',
        component: () => import('@/forms/LeagueForm')
    },
    {
        path: '/seasons',
        name: 'Seasons',
        component: () => import('@/routes/Season')
    },
    {
        path: '/seasons/:seasonId/:mode',
        name: 'Season Edit Form',
        component: () => import('@/forms/SeasonForm')
    },
    {
        path: '/seasons/:mode',
        name: 'Season Create Form',
        component: () => import('@/forms/SeasonForm')
    },
    {
        path: '/faqs',
        name: 'Faqs',
        component: () => import('@/routes/Faq')
    },
    {
        path: '/faqs/:faqId/:mode',
        name: 'Faq Edit Form',
        component: () => import('@/forms/FaqForm')
    },
    {
        path: '/faqs/:mode',
        name: 'Faq Create Form',
        component: () => import('@/forms/FaqForm')
    },
    {
        path: '/team-applications',
        name: 'Team Applications',
        component: () => import('@/routes/TeamApplication')
    },
    {
        path: '/team-applications/:tAId',
        name: 'Team Application Form',
        component: () => import('@/forms/TeamApplicationForm')
    },
    {
        path: '/rules',
        name: 'Rules',
        component: () => import('@/forms/RulesForm')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: vueConfig.publicPath,
    routes
})

router.beforeEach((to, from, next) => {
    if (!(from.path.includes(to.path) || to.path.includes(from.path))) {
        store.dispatch('setPage', 1)
    }
    const token = store.getters.getToken
    if ((!token || (token && jwtUtil.isJwtExpired(token))) && to.name !== 'Login') {
        store.dispatch('logout').then(() => next({ path: '/login' }))
    } else if (token && !jwtUtil.isJwtExpired(token) && to.name === 'Login') {
        next({ path: '/' })
    } else if (!token && to.name === 'Login') {
        next()
    } else {
        next()
    }
})

export default router
