<template>
    <v-app>
        <v-container class="px-0 mx-0 py-0" fluid no-gutters>
            <v-row no-gutters v-if="isLoggedIn && $route.name !== 'Match Stat Page'" class="px-0 mx-0">
                <v-col :cols="12" class="px-0 mx-0">
                    <topbar />
                    <v-divider />
                </v-col>
            </v-row>
            <v-row no-gutters :class="$route.name !== 'Match Stat Page' ? 'pt-14' : 'py-0'" class="px-0 mx-0">
                <v-col v-if="isLoggedIn && $route.name !== 'Match Stat Page'" cols="1">
                    <sidebar />
                </v-col>
                <v-col cols="12" class="px-0 mx-0">
                    <router-view :key="$route.path" />
                </v-col>
            </v-row>
        </v-container>
        <v-btn
            v-show="fab"
            v-scroll="onScroll"
            fab
            dark
            fixed
            bottom
            right
            color="black"
            @click="toTop"
        >
            <v-icon large>mdi-chevron-up</v-icon>
        </v-btn>
    </v-app>
</template>

<script>
    import Sidebar from '@/components/Sidebar'
    import Topbar from '@/components/Topbar'
    import jwtUtil from '@/utils/jwtUtil'

    export default {
        name: 'App',
        components: {
            Sidebar,
            Topbar
        },
        data: () => ({
            interval: null,
            fab: false
        }),
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn
            },
            drawer() {
                return this.$store.getters.getDrawer
            },
            jwtToken() {
                return this.$store.getters.getToken
            }
        },
        watch: {
            $route() {
                document.title = `Big CMS - ${this.$route.name}`
            }
        },
        created() {
            if (this.isLoggedIn) {
                this.interval = setInterval(() => {
                    this.checkJwt()
                }, 1000)
            }
        },
        methods: {
            onScroll(e) {
                if (typeof window === 'undefined') return
                const top = window.pageYOffset || e.target.scrollTop || 0
                this.fab = top > 20
            },
            toTop() {
                this.$vuetify.goTo(0)
            },
            checkJwt() {
                if (jwtUtil.isJwtExpired(this.jwtToken)) {
                    this.$store.dispatch('logout').then(() => this.$router.push({ path: '/login' }))
                    clearInterval(this.interval)
                }
            }
        }
    }
</script>

<style>
    .custom-container {
        padding-bottom: 20vh;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
</style>
