<template>
    <v-app-bar
        light
        clipped
        elevation="0"
        color="white"
        height="50px"
        fixed
        style="z-index: 100000;"
    >
        <v-app-bar-nav-icon color="black" class="included" @click.stop="setDrawer">
            <v-icon v-if="drawer" color="black">mdi-menu-open</v-icon>
            <v-icon v-else color="black">mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <v-img max-width="40px" contain class="mt-2" src="@/assets/main-logo.png" />
        <v-spacer />
        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                >
                    mdi-alert-octagon-outline
                </v-icon>
            </template>
            <span>You will be logged out in {{ tokenExpiration }} !!!</span>
        </v-tooltip>
        <div class="session-timer mx-4 pl-5" :class="remainingMinutes < 5 ? 'session-timer-danger' : ''">
            {{ tokenExpiration }}
            <span>
                <v-btn x-small icon class="mb-1" @click="refreshToken">
                    <v-icon color="black">
                        mdi-refresh
                    </v-icon>
                </v-btn>
            </span>
        </div> -->
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="black"
                    dark
                    x-small
                    class="mt-5"
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn fab small dark v-bind="attrs" v-on="on">
                    <v-icon color="orange">mdi-basketball</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item class="py-0 my-0" link @click="logout">
                    <v-icon class="pr-2">mdi-logout</v-icon> <v-list-item-title class="custom-text"> Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import userService from '@/services/user'
    import moment from 'moment'
    export default {
        data() {
            return {
                active: false,
                icon: 'mdi-view-grid-outline',
                tokenExpiration: '',
                interval: null,
                remainingMinutes: null
            }
        },
        computed: {
            refreshTokenExpiration() {
                return this.$store.getters.getRefreshTokenExpiration
            },
            drawer: {
                get() {
                    return this.$store.getters.getDrawer
                },
                set(value) {
                    return value
                }
            }
        },
        watch: {
            tokenExpiration() {
                this.remainingMinutes = moment(this.refreshTokenExpiration).subtract(30, 'minutes').diff(moment(), 'minutes')
            }
        },
        created() {
            this.setExpireTokenInterval()
        },
        methods: {
            setDrawer() {
                this.$store.dispatch('setDrawer', !this.drawer)
            },
            logout() {
                this.$store.dispatch('logout')
                this.$router.push(`/login`)
            },
            calculateTokenExpiration(newEpoch) {
                if (newEpoch) {
                    const expiration = moment(newEpoch)
                    const diff = expiration.diff(moment().utc().subtract(30, 'minutes'))
                    this.tokenExpiration = moment.utc(diff).format('mm:ss')
                }
            },
            refreshToken() {
                userService.refreshToken({
                    tokenValue: this.$store.getters.getRefreshToken
                }).then(resp => {
                    this.$store.dispatch('setRefreshToken', resp.data.refreshToken)
                    this.$store.dispatch('setRefreshTokenExpiration', resp.data.refreshTokenExpiration)
                    this.$store.dispatch('setToken', resp.data.token)
                })
            },
            setExpireTokenInterval() {
                if (this.interval) {
                    clearInterval(this.interval)
                }
                this.calculateTokenExpiration(this.refreshTokenExpiration)
                this.interval = setInterval(() => {
                    this.calculateTokenExpiration(this.refreshTokenExpiration)
                }, 1000)
            }
        }
    }
</script>

<style>

    .custom-text{
        font-size:small;
    }

    .session-timer{
        font-size: medium;
        font-weight: 700;
        width: 100px;
        height: 30px;
        border: 2px solid black;
    }

    .session-timer-danger{
        border-color: red !important;
        color: red !important;
    }

</style>
